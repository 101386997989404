<template>
  <page-layout>
    <div
      class="wrapper dashboard"
      v-if="$store.state.USER.user.organizationsName"
    >
      <div class="title-wrap">
        <h3 class="page-titles">
          Organizations
        </h3>
        <p class="">
          Manage your customers
        </p>
      </div>
      <div
        v-if="isDownloading"
        class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-80"
      >
        <button class=" btn btn-lg btn-circle btn-ghost loading" />
      </div>
      <div class="flex items-center">
        <label
          for="simple-search"
          class="sr-only"
        >Search</label>
        <div class="relative w-80">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            ><path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            /></svg>
          </div>
          <input
            type="text"
            id="filter"
            name="filter"
            v-model="filter"
            class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search"
            required
          >
        </div>
        <p class="mx-8 font-semibold">
          ({{ filteredTeams.length }} / {{ teams.length }})
        </p>
      </div>
      <table class="table mt-4 overflow-hidden table-compact table-zebra w-full">
        <thead>
          <tr>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Team Name </dropdown-table-header>
                <div />
              </div>
            </th>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Upload Full Team Report </dropdown-table-header>
                <div />
              </div>
            </th>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Download Full Team Report </dropdown-table-header>
                <div />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showLoading || teams.length <= 0">
            <td colspan="2">
              <div class="flex items-center justify-center w-full h-full">
                <button
                  v-if="showLoading"
                  class="btn btn-lg btn-circle btn-ghost loading"
                />
                <span v-if="teams.length <= 0"> No Teams </span>
              </div>
            </td>
          </tr>
          <tr
            v-for="team in filteredTeams"
            :key="team.id"
          >
            <td class="text-base w-1/3">
              {{ team.name }}
            </td>
            <td>
              <button
                @click="uploadFullTeamReport(team.id)"
                type="file"
              >
                <div
                  class="p-5 badge badge-primary rounded text-base w-96"
                >
                  Upload Report
                </div>
              </button>
            </td>
            <td>
              <button
                v-if="team.fullTeamReportUploaded"
                @click="downloadFullTeamReport(team)"
              >
                <div
                  class="p-5 badge badge-accent rounded text-base w-96"
                >
                  Download Report
                </div>
              </button>
              <span
                v-else
                class="bg-indigo-100 text-indigo-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-indigo-200 dark:text-indigo-900 flex items-center justify-evenly h-11 w-96"
              >
                Report not uploaded
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- invisible file upload element -->
      <input
        ref="fileInput"
        type="file"
        style="display: none;"
        @change="handleFileChange"
      >
    </div>
    <div
      class="fixed inset-0 z-10 overflow-y-auto"
      v-else
    >
      <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
        <div class="mt-2">
          <p class="text-sm text-gray-500">
            You are not currently logged in. To view customer teams, first log in as a customer under Customer accounts, then return here to view their teams.
          </p>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import DropdownTableHeader from '../components/DropdownTableHeader.vue'
import PageLayout from '@/layouts/PageLayout.vue'

import download from 'downloadjs'
import axios from 'axios'

export default {
  components: {
    DropdownTableHeader,
    PageLayout
  },
  data () {
    return {
      teams: [],
      filteredTeams: [],
      showLoading: false,
      selectedOrganizations: {},
      modal: false,
      filter: '',
      selectedFile: null,
      count: 0, // to keep track of number of file dialogue opens
      isDownloading: false
    }
  },
  async mounted () {
    const organizationId = this.$store.state.USER.user.organizationId
    if (organizationId) {
      this.showLoading = true
      await this.fetchAllTeams(organizationId)
      this.filterTeams()
      this.showLoading = false
    }
  },

  methods: {
    async fetchAllTeams (organizationId) {
      this.teams = await this.$api.getAdminTeams(organizationId)
    },

    async uploadFullTeamReport (campaignId) {
      this.count += 1
      const countValue = this.count
      await Promise.all([
        this.$api.getFullTeamReportUploadUrl(campaignId),
        new Promise((resolve, reject) => {
          this.$refs.fileInput.click() // trigger file input click event
          this.$watch('selectedFile', selectedFile => {
            // check if selected file and we have not opened more file dialogues
            if (selectedFile && this.count === countValue) {
              resolve(selectedFile)
            } else if (this.count > countValue) {
              reject(new Error('newer file selection dialogue detected'))
            }
          })
        })
      ]).then(([url, selectedFile]) => {
        // so handleFileChange will run when selecting same file
        this.$refs.fileInput.value = ''

        const formData = new FormData()
        formData.append('file', selectedFile)
        const headers = { 'Content-Type': 'multipart/form-data' }

        axios.put(url, formData, { headers }).then(async (res) => {
          if (res.status === 200) {
            await this.addReportUploaded(campaignId)
          }
        })
      }).catch(() => {})
    },

    async handleFileChange (event) {
      this.selectedFile = event.target.files[0]
    },

    async addReportUploaded (campaignId) {
      const updatedTeam = await this.$api.addReportUploaded(campaignId, true)
      // update the team to show that the report is uploaded
      this.teams = this.teams.map(team => team.id === campaignId ? updatedTeam : team)
      this.filterTeams()
    },

    async downloadFullTeamReport (team) {
      this.isDownloading = true
      const url = await this.$api.getFullTeamReportDownloadUrl(team.id)
      try {
        const { data, headers } = await axios.get(url, {
          responseType: 'blob'
        })
        const contentType = headers['content-type']
        download(data, `Full Team Report ${team.name}.pdf`, contentType)
        this.isDownloading = false
      } catch (error) {
        console.log(error)
        this.isDownloading = false
      }
    },

    async clearReportUploaded () {
      for (const team of this.teams) {
        await this.$api.addReportUploaded(team.id, false)
      }
    },

    filterTeams () {
      const lowerCaseFilter = this.filter.toLowerCase()
      this.filteredTeams = this.teams.filter(team => team.name.toLowerCase().includes(lowerCaseFilter))
    }
  },
  watch: {
    filter () {
      this.filterTeams()
    }
  }
}
</script>

<style>
</style>
